body {
  margin: 0;
  font-family: 'GabrielSans-Trial-Nm', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'GabrielSans-Trial-Nm', sans-serif;
}

@font-face {
  font-family: 'GabrielSans-Trial-Nm';
  src: url('./fonts/GabrielSans-Trial-Nm.woff') format('woff');
  /* Other font formats and sources can also be specified here */
}
