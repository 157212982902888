@tailwind base;
@tailwind components;
@tailwind utilities;

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-in-top {
	-webkit-animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-out-top {
	-webkit-animation: slide-out-top 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-top 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-out-left {
	-webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}
body::-webkit-scrollbar{
  display: none;
}
html,section.overflow-x-scroll {
  scrollbar-width: none;
}
body,section.overflow-x-scroll  {
  -ms-overflow-style: none;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-11-19 21:11:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-top {
  0% {
    height: 0px;
  }
  20% {
    height: 50px;
    z-index: 30;
  }
  40% {
    height: 100px;
  }
  60% {
    height: 150px;
  }
  80% {
    height: 200px;
  }
  100% {
    height: auto;
  }
}
@keyframes slide-in-top {
  0% {
    height: 0px;
  }
  20% {
    height: 50px;
    z-index: 30;
  }
  40% {
    height: 100px;
  }
  60% {
    height: 150px;
  }
  80% {
    height: 200px;
  }
  100% {
    height: auto;
  }
}
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@-webkit-keyframes border-slide {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}
@keyframes border-slide {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

@keyframes ping {
  0%, 100% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply the ping animation to an element */
.ping {
  animation: ping 1s ease-in-out infinite;
}


.App {
  text-align: center;
  font-family: 'GabrielSans-Trial-Nm', sans-serif;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.stat-card-container{
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.stat-card{
  width: 100%;
  max-width: 608px;
  /* min-width: 350px; */
  margin-bottom: 20px;
}

.min-w-400{
  min-width: 400px
}
.max-w-208{
  max-width: 208px;
}
.right45{
  right: 45%;
}
.right50{
  right: 50%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (min-width: 785px) {
  .stat-card{
    min-width: 620px;
  }
}
@media screen and (min-width: 900px) {
  .stat-card{
    min-width: 600px;
  }
  .stat-card:nth-child(2n-1) {
    margin-right: 20px;
  }
}
.footer-txt{
  color: #5E5E5E;
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
}
.footer-txt:hover{
  color: #5E5E5E;
  color: #C734E5;
}
.myborder{
  display: inline-block;
}
.myborder::after{
  display: block;
  content: "";
  margin: -2px 0px auto 0px;
  width: 0;
  height: 2px;
  background-color: #201B37;
  transition: width 0.3s;
}
/* .myborder:hover::after{
  width: 100%;
} */
.myborder.stay::after{
  display: block;
  content: "";
  margin: -2px 0px auto 0px;
  width: 100%;
  height: 2px;
  background-color: #201B37;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  /* font-family: 'Montserrat'; */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #C734E5;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* Fonts */

.gbst-bd-itc{
  font-family: 'GabrielSans-Trial-BdItc';
}
.gbst-bd{
  font-family: 'GabrielSans-Trial-Bd';
}
.gbst-md{
  font-family: 'GabrielSans-Trial-Md';
}
.gbst-md-itc{
  font-family: 'GabrielSans-Trial-Md-Itc';
}
.gbst{
  font-family: 'GabrielSans-Trial-Nm';
}
.gbst-itc{
  font-family: 'GabrielSans-Trial-NmItc';
}

.gbst-lt{
  font-family: 'GabrielSans-Trial-Lt';
}

@font-face {
  font-family: 'GabrielSans-Trial-Nm';
  src: url('./fonts/HankenGrotesk-Regular.ttf') format('truetype');
  /* Other font formats and sources can also be specified here */
}

@font-face {
  font-family: 'GabrielSans-Trial-Md';
  src: url('./fonts/HankenGrotesk-Medium.ttf') format('truetype');
  /* Other font formats and sources can also be specified here */
}

@font-face {
  font-family: 'GabrielSans-Trial-Lt';
  src: url('./fonts/HankenGrotesk-Light.ttf') format('truetype');
  /* Other font formats and sources can also be specified here */
}

@font-face {
  font-family: 'GabrielSans-Trial-Bd';
  src: url('./fonts/HankenGrotesk-Bold.ttf') format('truetype');
  /* Other font formats and sources can also be specified here */
}

@font-face {
  font-family: 'GabrielSans-Trial-NmItc';
  src: url('./fonts/HankenGrotesk-Italic.ttf') format('truetype');
  /* Other font formats and sources can also be specified here */
}

@font-face {
  font-family: 'GabrielSans-Trial-Md-Itc';
  src: url('./fonts/HankenGrotesk-MediumItalic.ttf') format('truetype');
  /* Other font formats and sources can also be specified here */
}

@font-face {
  font-family: 'GabrielSans-Trial-BdItc';
  src: url('./fonts/HankenGrotesk-BoldItalic.ttf') format('truetype');
  /* Other font formats and sources can also be specified here */
}
